<template>
  <div class="overview">
    <div
      class="chapter"
      @click="$router.push({
        name: 'course-module',
        params: {
          chapter: $route.params.slug,
          module: currentModule.id
        }
      })"
    >
      <img src="@/assets/course/arrow-left-chapter.svg" alt="arrow-left">
      {{ currentModule?.title }}
    </div>
    <div class="overview_list">
      <div
        v-for="item in menu"
        class="topic"
        :class="{'active': item.id === currentTopicIndex, 'noaccess': !item.available}"
        @click="toggleTopic(item.id)"
      >
        <div class="topic_header">
          <div class="name">
            {{ item.name }}
          </div>
          <div class="icon">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.75 7.5L10 13.75L16.25 7.5" stroke="#0B1F35" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <div class="topic_body">
          <div
            v-for="(material, materialKey) in item['materials']"
            class="topic_item"
            :class="{'active': isCurrentPage(item), 'noaccess': !isAvailable(material)}"
            @click="e => changeTheme(e, item, materialKey)"
          >
            <div class="icon" v-if="material.type === 'text'">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.875 3.75H3.125C2.77982 3.75 2.5 4.02982 2.5 4.375V15.625C2.5 15.9702 2.77982 16.25 3.125 16.25H16.875C17.2202 16.25 17.5 15.9702 17.5 15.625V4.375C17.5 4.02982 17.2202 3.75 16.875 3.75Z"
                  stroke="#0B1F35" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.9375 7.5H14.0625" stroke="#0B1F35" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M5.9375 10H14.0625" stroke="#0B1F35" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M5.9375 12.5H14.0625" stroke="#0B1F35" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="icon" v-else-if="material.type === 'audio'">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.66602 4.16683C1.66602 3.70659 2.03911 3.3335 2.49935 3.3335H17.4993C17.9596 3.3335 18.3327 3.70659 18.3327 4.16683V15.8335C18.3327 16.2937 17.9596 16.6668 17.4993 16.6668H2.49935C2.03911 16.6668 1.66602 16.2937 1.66602 15.8335V4.16683Z"
                  stroke="#0B1F35" stroke-linejoin="round"/>
                <path d="M15 3.3335V16.6668" stroke="#0B1F35" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5 3.3335V16.6668" stroke="#0B1F35" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.834 7.5H18.334" stroke="#0B1F35" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.834 12.5H18.334" stroke="#0B1F35" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1.66602 7.5H4.16602" stroke="#0B1F35" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1.66602 12.5H4.16602" stroke="#0B1F35" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.75 7.9165L12.0833 9.99984L8.75 12.0832V7.9165Z" stroke="#0B1F35"
                      stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="icon" v-else-if="material.type === 'presentation'">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.66602 3.3335H18.3327" stroke="#0B1F35" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.33398 3.3335H16.6673V14.1668H3.33398V3.3335Z"
                      stroke="#0B1F35" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.916 7.5L14.166 8.75L12.916 10" stroke="#0B1F35" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M7.08398 10L5.83398 8.75L7.08398 7.5" stroke="#0B1F35" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M6.66602 17.4998L9.99935 14.1665L13.3327 17.4998" stroke="#0B1F35" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="text">
              {{ material.title }}
            </div>
          </div>
          <div
            v-for="quiz in item['quizzes']"
            class="topic_item"
            :class="{'active': quiz.id === $props.quizId, 'noaccess': !isAvailable(quiz)}"
            @click="e => openQuiz(e, quiz)"
          >
            <div class="icon">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.5 16.8749H3.75C3.58424 16.8749 3.42527 16.8091 3.30806 16.6918C3.19085 16.5746 3.125 16.4157 3.125 16.2499V12.7588C3.125 12.6767 3.14117 12.5954 3.17258 12.5196C3.20398 12.4438 3.25002 12.3749 3.30806 12.3168L12.6831 2.94185C12.8003 2.82464 12.9592 2.75879 13.125 2.75879C13.2908 2.75879 13.4497 2.82464 13.5669 2.94185L17.0581 6.43296C17.1753 6.55017 17.2411 6.70915 17.2411 6.87491C17.2411 7.04067 17.1753 7.19964 17.0581 7.31685L7.5 16.8749Z"
                  stroke="#0B1F35" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.625 5L15 9.375" stroke="#0B1F35" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="text">
              {{ quiz.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "SideMenuInner",
  props: ['quizId'],
  data() {
    return {
      currentTopicIndex: this.$route.params.theme ? Number(this.$route.params.theme) : 0
    }
  },
  methods: {
    toggleTopic(topicIndex) {
      if (this.currentTopicIndex === topicIndex) {
        this.currentTopicIndex = -1
      } else {
        this.currentTopicIndex = topicIndex
      }
    },
    changeTheme(event, item, materialKey) {
      event.stopPropagation();

      if (this.$route.matched.some(({ name }) => name === 'course-theme') && (Number(this.$route.params.theme) === item.id)) {
        this.$emit('switchMaterial', materialKey);
      } else {
        this.$router.push({
          name: 'course-theme',
          params: {
            slug: this.getCourse.slug,
            module: item.chapter_id,
            theme: item.id,
          },
        });
      }
    },
    isCurrentPage(item) {
      return (Number(this.$route.params.theme) === item.id) && !this.$props.quizId;
    },
    toPage({link}) {
      this.$router.push(link);
    },
    openQuiz(event, quiz) {
      event.stopPropagation();
      this.$emit('openQuiz', quiz.id, quiz.available);
    },
    isAvailable(item) {
      return item.available;
    },
  },
  computed: {
    ...mapGetters([
      'getCourse',
      'getSideMenu'
    ]),
    menu() {
      return this.currentModule?.themes;
    },
    currentModule() {
      return this.getCourse?.modules.find(({ id }) => Number(this.$route.params.module) === id);
    },
  }
}
</script>

<style lang="scss" scoped>
.overview {
  background: #fff;
  width: 400px;
  min-width: 400px;
  border-radius: 20px;
  padding: 0 28px 28px 0;
  margin-left: auto;
  overflow: hidden;

  &_title {
    font-size: 22px;
    font-weight: 600;
    margin: 28px 0 28px 28px;
  }

  &_current {
    background: #fff;
    height: 43px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    gap: 16px;
    margin-bottom: 8px;
  }

  .chapter {
    background: #16C95D;
    display: flex;
    gap: 15px;
    align-items: center;
    color: #fff;
    font-weight: 500;
    padding: 10px 28px;
    width: fit-content;
    border-radius: 20px 0;
    margin-bottom: 16px;
    cursor: pointer;
  }

  &_item {
    background: #F6F7F8;
    border-radius: 8px;
    padding: 12px 20px;
    margin-top: 15px;
    margin-left: 28px;
    cursor: pointer;

    .name {
      color: #0B1F35;
    }

    &.active, &:hover {
      background: #007BFC;

      .name {
        color: #fff;
      }
    }

    &.noaccess {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  .topic {
    background: #F6F7F8;
    border-radius: 8px;
    padding: 0 20px 16px;
    margin-left: 28px;
    margin-top: 12px;
    max-height: 70px;
    overflow: hidden;
    transition: 0.5s ease;

    &.active {
      max-height: 400px;
      background: #E9F4FF;

      .name {
        color: #007BFC;
        font-weight: 600;
      }

      .topic_header .icon {
        transform: rotate(180deg);

        path {
          stroke: #007BFC;
        }
      }
    }

    &.noaccess {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }

    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 0;
      margin-bottom: 16px;
      cursor: pointer;

      .icon {
        transition: 0.5s ease;
      }
    }

    &_item {
      display: flex;
      align-items: center;
      padding: 12px 20px;
      gap: 12px;
      border-radius: 8px;
      margin-bottom: 16px;
      cursor: pointer;

      &.active, &:hover {
        background: #007BFC;
        color: #fff;

        svg path {
          // fill: white;
          stroke: white;
        }
      }

      &.noaccess {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
      }

      .text {
        white-space: nowrap;
        overflow: hidden;
        line-clamp: 1;
        width: 80%;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .overview {
    background: transparent;
    width: 100%;
    max-width: 800px;
    margin-left: 0;
    padding: 0;
    min-width: auto;

    &_current {
      display: flex;

      .icon {
        transition: 0.5s ease;
      }

      &.active {
        .icon {
          transform: rotate(180deg);
        }
      }
    }

    &_title {
      margin-top: 0;
    }

    &_item, .topic {
      margin-left: 0;
    }

    &_list {
      background: #fff;
      padding: 8px 16px;
      border-radius: 10px;
      max-height: 1000px;
      overflow: hidden;

      &.hidden {
        max-height: 0;
        padding: 0 16px;
      }
    }
  }
}
</style>
