<template>
  <div class="desc">
    <div class="desc_title">
      {{ material.title }}
    </div>
    <div class="desc_item">
      <div class="desc_item-info">
        <span class="emoji">👉</span>
        <div class="name">
          {{ material.title }}
        </div>
        <div class="text" v-html="material.text" />

        <div class="p-5" v-if="material.document_path">
          <a
            target="_blank"
            class="theme_one_desc_download"
            :href="material.document_path"
          >
            Скачать документ
          </a>
        </div>

        <template v-if="material.attachments && material.attachments.length > 0">
          <div class="pb-5">
            <a
              target="_blank"
              class="theme_one_desc_download"
              v-for="attachment in material.attachments"
              :href="attachment.url"
            >
              {{ attachment.link_name }}
            </a>
          </div>
        </template>

        <div class="theme_one_desc_btn">
          <a
            v-if="showPrev"
            @click="prev"
            class="theme_one_desc_btn_back_with_arrow"
          >
            Назад
          </a>
          <a
            v-if="showNext"
            @click="next"
            class="theme_one_desc_btn_next"
          >
            Далее
          </a>
          <a
            v-if="showNextTheme"
            @click="$emit('goToNextModule')"
            class="theme_one_desc_btn_next"
          >
            Следующая тема
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DocumentMaterial",
  props: ["material", "showNext", "showPrev", "showNextTheme"],
  methods: {
    prev() {
      this.$emit("prev");
    },
    next() {
      this.$emit("next");
    },
  },
};
</script>

<style lang="scss" scoped>
.desc {
  background: #fff;
  border-radius: 20px;
  padding-bottom: 70px;

  &_title {
    background: #16C95D;
    border-radius: 0 0 20px 20px;
    padding: 8px 20px;
    width: fit-content;
    color: #fff;
    font-weight: 600;
    margin: 0 auto 40px;
  }

  &_item {
    display: flex;
    align-items: center;

    &.second {
      .desc_item-info {
        margin-right: 50px;
        margin-left: 38px;
      }
    }

    &-info {
      margin-left: 86px;
      margin-right: 40px;
      position: relative;

      .name {
        color: #0B1F35;
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 12px;
      }

      .emoji {
        position: absolute;
        font-size: 24px;
        left: -36px;
      }
    }
  }

  .button {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 12px;
    color: #007BFC;
    font-weight: 600;
  }
}

@media screen and (max-width: 1200px) {
  .desc {
    &_item {
      flex-direction: column;
      margin-bottom: 20px;

      &.second {
        flex-direction: column-reverse;
      }

      &-info {
        margin: 0 30px 0 50px !important;
      }
    }
  }
}
</style>
