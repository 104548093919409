<template>
  <div class="chapter" v-loading.fullscreen.lock="loading">
    <div class="chapter_body">

      <b-modal id="show" hide-footer hide-header centered size="lg">
        <div
          class="store__product__modal"
          id="dialog"
          style="background: #f7e9f6; color: #c460c0"
        >
          <button
            @click="$bvModal.hide('show')"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true"></span>
          </button>
          <h1>Вы успешно завершили курс</h1>
          <br />
          На ваш счет начислено 25 коинов
        </div>
      </b-modal>

      <VideoMaterial
        :showPrev="showPrev"
        :showNext="showNext"
        :show-next-theme="showNextTheme"
        @prev="prev"
        @next="next"
        @goToNextModule="goToNextModule"
        :key="materialKey"
        :material="currentMaterial"
        v-if="checkMaterial('video')"
      />
      <PresentationMaterial
        :showPrev="showPrev"
        :showNext="showNext"
        :show-next-theme="showNextTheme"
        @prev="prev"
        @next="next"
        @goToNextModule="goToNextModule"
        :key="materialKey"
        :material="currentMaterial"
        v-if="checkMaterial('presentation')"
      />
      <DocumentMaterial
        :showPrev="showPrev"
        :showNext="showNext"
        :show-next-theme="showNextTheme"
        @prev="prev"
        @next="next"
        @goToNextModule="goToNextModule"
        :key="materialKey"
        :material="currentMaterial"
        v-if="checkMaterial('text')"
      />
      <DocumentMaterial
        :showPrev="showPrev"
        :showNext="showNext"
        :show-next-theme="showNextTheme"
        @prev="prev"
        @next="next"
        @goToNextModule="goToNextModule"
        :key="materialKey"
        :material="currentMaterial"
        v-if="checkMaterial('document')"
      />
      <AudioMaterial
        :showPrev="showPrev"
        :showNext="showNext"
        :show-next-theme="showNextTheme"
        @prev="prev"
        @next="next"
        @goToNextModule="goToNextModule"
        :key="materialKey"
        :material="currentMaterial"
        v-if="checkMaterial('audio')"
      />

      <ExerciseMaterial
        v-if="quizId"
        :key="quizKey"
        :quiz-id="quizId"
        @goToNextModule="goToNextModule"
        @reload="reloadQuiz"
        @onExerciseFinish="onExerciseFinish"
      />

      <div
        v-if="!quizId && currentTheme && !existsMaterials"
        class="theme_one_exercises_desc"
      >
        <div class="theme_one_exercises_desc_text_container">
          <div class="theme_one_desc_title">{{ $t("Nothing_found") }}</div>
        </div>
      </div>

    </div>

    <SideMenuInner
      :quiz-id="quizId"
      @openQuiz="openQuiz"
      @switchMaterial="switchMaterial"
    />
  </div>
</template>

<script>
import VideoMaterial from "@/views/old/Courses/Materials/VideoMaterial";
import PresentationMaterial from "@/views/old/Courses/Materials/PresentationMaterial";
import DocumentMaterial from "@/views/old/Courses/Materials/DocumentMaterial";
import AudioMaterial from "@/views/old/Courses/Materials/AudioMaterial";
import ChapterNavigation from "@/components/ChapterNavigation";
import ExerciseMaterial from "@/views/old/Courses/Materials/ExerciseMaterial";
import Vue from "vue";
import SideMenuInner from "@/components/course/SideMenuInner";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CourseTheme",
  components: {
    SideMenuInner,
    ChapterNavigation,
    VideoMaterial,
    PresentationMaterial,
    DocumentMaterial,
    ExerciseMaterial,
    AudioMaterial,
  },
  data() {
    return {
      course: false,
      materialKey: 0,
      currentMaterialId: 0,
      quizId: null,
      quizKey: 0,
      showTheme: true,
      timeout: null,
      routeLeft: false,
    };
  },
  mounted() {
    if (!this.course) {
      this.fetchCourse(this.$route.params.slug);
    }
    this.getCourseDetails();
  },
  methods: {
    ...mapActions({
      fetchCourse: 'fetchCourse',
    }),
    getCourseDetails() {
      let apiRoot = API_ROOT;
      let slug = this.$route.params.slug;
      this.$http
        .get(`${apiRoot}/api/course/${slug}`)
        .then((res) => {
          this.course = res.body;
          if (!this.existsMaterials) {
            let quizId = this.currentTheme.quizzes[0]
              ? this.currentTheme.quizzes[0].id
              : null;
            this.openQuiz(quizId, true);
          }
          let materialId = this.currentMaterial.id
            ? this.currentMaterial.id
            : false;
          let timeout = this.currentMaterial.log_timeout
            ? this.currentMaterial.log_timeout
            : 0;
          if (!this.currentMaterial.finished) {
            setTimeout(
              () => {
                if (this.routeLeft) return false;
                this.$http
                  .get(
                    `${window.API_ROOT}/api/course/${this.$route.params.slug}`,
                  )
                  .then((res) => {
                    for (let i = 0; i < res.body.modules.length; i++) {
                      if (res.body.modules[i].id == this.currentModuleId) {
                        if (res.body.modules[i + 1].id == undefined) {
                        }
                      }
                    }
                  })
                  .catch((err) => {
                    if (err.status === 404) {
                      this.$router.push({ name: "not-found" });
                    }
                    console.log(err);
                  })
                  .finally(() => {});

                this.$http
                  .post(`${apiRoot}/api/course/material/finish/${materialId}`)
                  .then(() => {
                    this.$http
                      .post(`${apiRoot}/api/user/change-coins`, { count: 1 })
                      .then(() => {});
                    this.$http
                      this.fetchCourse(this.$route.params.slug)
                      .get(`${apiRoot}/api/course/${slug}`)
                      .then((res) => {
                        this.course = res.body;
                      });
                  });
              },
              timeout * 10 * 60,
            );
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {});
    },
    switchMaterial(id) {
      if (!this.currentTheme.materials[id].available) {
        Vue.toastr({
          description: `Материал недоступен`,
          type: "error",
        });
        return;
      }
      let apiRoot = API_ROOT;
      let slug = this.$route.params.slug;
      this.quizId = null;
      this.currentMaterialId = id;
      let materialId = this.currentMaterial.id;
      setTimeout(
        () => {
          if (this.routeLeft) return false;
          this.$http
            .post(`${apiRoot}/api/course/material/finish/${materialId}`)
            .then(() => {
              this.fetchCourse(this.$route.params.slug);
              this.$http.get(`${apiRoot}/api/course/${slug}`).then((res) => {
                this.course = res.body;
              });
            });
        },
        this.currentMaterial.log_timeout * 10 * 60,
      );
      this.materialKey++;
    },
    checkMaterial(material) {
      return (
        this.currentTheme &&
        this.currentTheme.materials.length &&
        this.currentMaterialId !== null &&
        this.currentMaterial.type === material
      );
    },
    prev() {
      let materialKey = this.currentMaterialId - 1;
      let mat = this.currentTheme.materials.find(
        (material, key) => key === materialKey,
      );
      if (mat) this.switchMaterial(materialKey);
    },
    next() {
      let materialKey = this.currentMaterialId + 1;
      let mat = this.currentTheme.materials.find(
        (material, key) => key === materialKey,
      );
      if (mat) this.switchMaterial(materialKey);
      if (mat) {
        return;
      }
      if (this.currentTheme.quizzes) {
        this.openQuiz(
          this.currentTheme.quizzes[0].id,
          this.currentTheme.quizzes[0].available,
        );
      }
    },
    goToNextModule() {
      const { slug } = this.$route.params;

      this.$http
        .get(`${window.API_ROOT}/api/course/${slug}`)
        .then((res) => {
          const { modules } = res.body;

          let redirected = false;
          for (let i = 0; i < modules.length; i++) {
            if (modules[i].id == this.currentModuleId) {
              for (let k = 0; k < modules[i].themes.length; k++) {
                if (this.currentThemeId == modules[i].themes[k].id) {
                  if (modules[i].themes[k + 1]) {
                    redirected = true;
                    if (modules[i].themes[k + 1].available) {
                      window.location.href = `/profile/courses/${slug}/module/${
                        modules[i].id
                      }/theme/${modules[i].themes[k + 1].id}`;
                      return;
                    } else {
                      Vue.toastr({
                        description: this.$t("disabled_next"),
                        type: "error",
                      });
                      return;
                    }
                  }
                }
              }
            }
          }

          if (redirected == false) {
            for (let i = 0; i < res.body.modules.length; i++) {
              if (res.body.modules[i].id === this.currentModuleId) {
                if (res.body.modules[i + 1].id) {
                  if (res.body.modules[i].themes[0].available) {
                    window.location.href = `/profile/courses/${
                      this.$route.params.slug
                    }/module/${res.body.modules[i + 1].id}/theme/${
                      res.body.modules[i + 1].themes[0].id
                    }`;
                    return;
                  } else {
                    Vue.toastr({
                      description: this.$t("disabled_next"),
                      type: "error",
                    });
                    return;
                  }
                }
              }
            }
          }
        })
        .catch((err) => {
          if (err.status === 404) {
            this.$router.push({ name: "not-found" });
          }
          console.log(err);
        })
        .finally(() => {});
    },
    openQuiz(quiz, available) {
      if (!available) {
        Vue.toastr({
          description: `Упражнение недоступно`,
          type: "error",
        });
        return;
      }
      this.currentMaterialId = null;
      this.quizId = quiz;
      this.quizKey++;
    },
    reloadQuiz(id) {
      this.currentMaterialId = null;
      this.quizId = id;
      this.quizKey++;
    },
    onExerciseFinish() {
      this.fetchCourse(this.$route.params.slug)
    }
  },
  computed: {
    ...mapGetters([
      'isCourseLoading',
      'getCourse',
    ]),
    loading() {
      return this.isCourseLoading
    },
    showNext: function () {
      let materialKey = this.currentMaterialId + 1;
      let mat = this.currentTheme.materials.find(
        (material, key) => key === materialKey,
      );
      if (this.currentTheme.quizzes.length) {
        mat = true;
      }
      return !!mat;
    },
    showPrev: function () {
      let materialKey = this.currentMaterialId - 1;
      let mat = this.currentTheme.materials.find(
        (material, key) => key === materialKey,
      );
      return !!mat;
    },
    showNextTheme() {
      if (!this.showNext && this.showPrev) {
        return true;
      }

      const themeIndex = this.currentModule.themes.indexOf(this.currentTheme);
      if (
        this.currentTheme.quizzes.length <= 0 &&
        themeIndex < this.currentModule.themes.length - 1
      ) {
        return true;
      }

      const moduleIndex = this.course.modules.indexOf(this.currentModule);
      if (
        this.currentTheme.quizzes.length <= 0 &&
        moduleIndex < this.course.modules.length - 1
      ) {
        return true;
      }

      return false;
    },
    currentModule: function () {
      if (!this.course.modules) return false;
      return this.course.modules.find(
        (module) => module.id === this.currentModuleId,
      );
    },
    currentTheme: function () {
      if (!this.currentModule) return false;
      return this.currentModule.themes.find(
        (theme) => theme.id === this.currentThemeId,
      );
    },
    currentModuleId: function () {
      return parseInt(this.$route.params.module);
    },
    currentThemeId: function () {
      return parseInt(this.$route.params.theme);
    },
    currentMaterial: function () {
      if (!this.currentTheme && !this.currentTheme.materials) {
        return;
      }
      return this.currentTheme.materials[this.currentMaterialId];
    },
    existsMaterials: function () {
      return this.currentTheme.materials.length;
    },
  },
  beforeRouteLeave(to, from, next) {
    this.routeLeft = true;
    next();
  },
};
</script>

<style lang="scss" scoped>
.chapter {
  display: flex;
  align-items: flex-start;
  gap: 32px;

  &_body {
    width: calc(100% - 432px);
  }

  &_buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }

  .btn {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 240px;
    height: 52px;
    border-radius: 10px;
    margin-top: 32px;

    &.active {
      background: #007BFC;
      color: #fff;
    }
  }
}

@media screen and (max-width: 992px) {
  .chapter {
    flex-direction: column-reverse;
    gap: 20px;
    padding-top: 60px;

    &_body {
      width: 100%;
      max-width: 800px;
    }
  }
}
</style>
