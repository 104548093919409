<template>
  <div class="theme_one_video_desc">
    <div class="theme_one_desc_text_container">
      <div class="theme_one_desc_title">
        <div class="theme_one_desc_title_text">{{ material.title }}</div>
        <!--        <div class="theme_one_desc_title_time">10 минут</div>-->
      </div>
      <iframe
        :src="material.video"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer;
                    autoplay;
                    clipboard-write;
                    encrypted-media;
                    gyroscope;
                    picture-in-picture"
        allowfullscreen="allowfullscreen"
        class="theme_one_desc_video"
      />
      <div
        v-if="material.attachments && material.attachments.length"
        class="pt-4"
      >
        <a
          target="_blank"
          class="theme_one_desc_download"
          v-for="attachment in material.attachments"
          :href="attachment.url"
        >
          {{ attachment.link_name }}
        </a>
      </div>
      <div class="theme_one_desc_btn pt-4">
        <a v-if="showPrev" @click="prev" class="theme_one_desc_btn_back">
          Назад
        </a>
        <a v-if="showNext" @click="next" class="theme_one_desc_btn_next">
          Далее
        </a>
        <a
          v-if="showNextTheme"
          @click="$emit('goToNextModule')"
          class="theme_one_desc_btn_next"
        >
          Следующая тема
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoMaterial",
  props: ["material", "showNext", "showPrev", "showNextTheme"],
  methods: {
    prev() {
      this.$emit("prev");
    },
    next() {
      this.$emit("next");
    },
  },
};
</script>

<style scoped></style>
