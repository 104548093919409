<template>
  <div class="theme_one_prez_desc">
    <div class="theme_one_prez_desc_container">
      <div class="theme_one_desc_text_container">
        <div class="theme_one_desc_text_container_label">{{ currentPage }}</div>
        <pdf
          style="display: inline-block; width: 100%"
          :page="currentPage"
          :src="pdfPath"
          @error="handleError"
          @loaded="handleLoaded"
        ></pdf>
        <svg
          @click="show(pageCount)"
          class="view-all"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M15.75 4.5H19.5V8.25"
            stroke="#305AA6"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.25 19.5H4.5V15.75"
            stroke="#305AA6"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19.5 15.75V19.5H15.75"
            stroke="#305AA6"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 8.25V4.5H8.25"
            stroke="#305AA6"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div class="theme_one_desc_img_block">
        <div
          class="theme_one_desc_img_container theme_one_desc_img_container_mobile"
        >
          <div
            class="theme_one_desc_slide_page theme_one_desc_slide_page_mobile"
            v-for="page in pageCount"
            :style="getPageScreenshot(page)"
            @click="goToPage(page)"
          >
            <i :class="{ active: page === currentPage }">{{ page }}</i>
          </div>
        </div>
      </div>
    </div>
    <div class="theme_one_prez_slider">
      <a @click="prevPage" class="theme_one_prez_slider_prev"
        >Предыдущий слайд</a
      >
      <a @click="nextPage" class="theme_one_prez_slider_next"
        >Следующий слайд</a
      >
    </div>
    <div
      v-if="material.attachments && material.attachments.length"
      class="pb-5"
    >
      <a
        target="_blank"
        class="theme_one_desc_download"
        v-for="attachment in material.attachments"
        :href="attachment.url"
        >{{ attachment.link_name }}</a
      >
    </div>
    <div class="theme_one_desc_btn">
      <a v-if="showPrev" @click="prev" class="theme_one_desc_btn_back">Назад</a>
      <a v-if="showNext" @click="next" class="theme_one_desc_btn_next">Далее</a>
      <a
        v-if="showNextTheme"
        @click="$emit('goToNextModule')"
        class="theme_one_desc_btn_next"
      >
        Следующая тема
      </a>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import VueViewer from "v-viewer";
import Vue from "vue";

Vue.use(VueViewer);
export default {
  name: "PresentationMaterial",
  props: ["material", "showNext", "showPrev", "showNextTheme"],
  data() {
    return {
      currentPage: 1,
      presentation: {},
    };
  },
  components: {
    pdf,
  },
  methods: {
    show(pages) {
      let items = [];
      for (let i = 0; i < pages; i++) {
        let pageText = `-${i}.jpg`;
        let screenUrl = this.pdfPath.replace(".pdf", pageText);
        items.push(screenUrl);
      }
      console.log(items);

      this.$viewerApi({
        images: items,
      });
    },
    getPageScreenshot(page) {
      let pageText = `-${page - 1}.jpg`;
      let screenUrl = this.pdfPath.replace(".pdf", pageText);
      return `background-image: url('${screenUrl}')`;
    },
    goToPage(page) {
      this.currentPage = page;
    },
    handleError() {},
    handleLoaded() {},
    prevPage() {
      let prevPage = this.currentPage - 1;
      if (prevPage > 0) {
        this.currentPage = prevPage;
      }
    },
    nextPage() {
      let nextPage = this.currentPage + 1;
      if (nextPage <= this.pageCount) {
        this.currentPage = nextPage;
      }
    },
    prev() {
      this.$emit("prev");
    },
    next() {
      this.$emit("next");
    },
  },
  computed: {
    pdfPath: function () {
      let pdfPath = this.material.presentation_path.replace(".pptx", ".pdf");
      pdfPath = pdfPath.replace(".ppt", ".pdf");
      return pdfPath;
    },
    pageCount: function () {
      return this.material.pages;
    },
    src: function () {
      return pdf.createLoadingTask(this.pdfPath);
    },
  },
};
</script>

<style scoped>
.view-all {
  cursor: pointer;
  right: 20px;
  bottom: 20px;
  position: absolute;
}
</style>
